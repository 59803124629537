
.slick-prev:before {
  color: orange;
}
.slick-next:before {
  color: orange;
}

.icon {
  font-size: 30px;
  color: rgba(43,42,41,255);
  transition: 0.3s ease-in;
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
}
.icon-contact {
  font-size: 20px;
  color:white;
  transition: 0.3s ease-in;
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
}

.icon-phone {
  font-size: 20px;
  color: rgba(43,42,41,255);
  transition: 0.3s ease-in;
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
}

.icon-menu {
  font-size: 25px;
  color: rgba(43,42,41,255);
  transition: 0.3s ease-in;
  cursor: pointer;
  display: none;
}

@media (max-width: 850px) {
  .icon-menu {
    display: block;
  }

  .icon-phone {
    font-size: 16px;
  }

  .icon{
    font-size: 20px;
  }
  .icon-contact{
    font-size: 13px;
    /* display: none; */

  }
}
